/*
スタイルの勉強のため、Gatsby公式チュートリアルをやってみる
https://www.gatsbyjs.com/docs/tutorial/
*/
import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import TopImageTag from '../../components/topImageTag'
import {
  titleStyle,
  blogTopImage,
  blogTopImageImg,
  blogLink,
  blogListBorder,
  blogLinkText,
  blogDateText,
  blogSummaryText,
} from './index.module.css'


const BlogPage = ({ data }) => {
  console.log(data.allMdx.nodes)
  console.log('Array length:',data.allMdx.nodes.length)
  return (
    <Layout pageTitle="Blog記事リスト" pageDescription="（Blog記事リストの説明）">
      <h1 className={titleStyle}>Blog</h1>
      <div>
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
          <Link to={`/blog/${node.slug}`}
            className={blogLink}
          >
            <div className={blogListBorder}>
              <div className={blogLinkText}>
                {node.frontmatter.title}
              </div>
              <div className={blogDateText}>
                Last updated: {node.frontmatter.date}
              </div>
              <div className={blogTopImage}>
                <TopImageTag className={blogTopImageImg} node={node.frontmatter}/>
              </div>
              <div className={blogSummaryText}>
                {node.frontmatter.summary}
              </div>
            </div>
          </Link>
          </article>
        ))
      }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date
          title
          summary
          topImage{
            childImageSharp {
              gatsbyImageData(width: 750)
            }
          }
        }
        id
        slug
      }
    }
  }
`

export default BlogPage